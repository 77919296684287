import MainLayout from "layout/MainLayout";
import { NotFound, TrackOrder } from "./PageAsync";
import PropTypes from "prop-types";
// ==============================|| MAIN ROUTING ||============================== //

// export default MainRoutes;

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout classes={classes} />,
    children: [
      {
        path: "/track-order/:order_id?/:token?",
        element: <TrackOrder classes={classes} />,
      },

      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};

import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import { saveDataToLocalStorage } from "utils/LocalStore.util";

// routes
// import MainRoutes from "./MainAppRoutes";

export function AppRoute() {
  const classes = useStyles() as any;

  let user = {
    id: 1,
    created_at: "2023-08-16T13:59:33.259Z",
    updated_at: "2023-08-16T13:59:33.259Z",
    deleted_at: "2023-08-16T13:59:33.259Z",
    created_by: null,
    updated_by: null,
    deleted_by: null,
    is_active: false,
    activated_by: null,
    is_deleted: false,
    resource_id: null,
    entity_type: "contact",
    contact_type: null,
    customer_type: null,
    business_title: null,
    customer_id: null,
    is_admin: false,
    first_name: "Muhammad",
    last_name: "Hassaan",
    gender: "M",
    email: "hassan@hitech-prime.com",
    phone_code: null,
    phone: null,
    password: null,
    city: null,
    country: null,
    location: null,
    address: null,
    is_verified: null,
    otp: null,
    otp_expire: null,
    otp_verified: false,
  };
  // saveDataToLocalStorage("user", user);

  let app_routes = MainAppRoutes({ classes });

  // eslint-disable-next-line
  return useRoutes([app_routes]);
}

export default AppRoute;

/*
  Created By Muhammad Hassaan
  Date 12 April 2023
*/
import React from "react";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";
import { Outlet } from "react-router";
import Header from "./Header";

export default function MainLayout(props:any) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
     

      <div style={{ width: "100%", margin: "100px 40px 20px 40px" }}>
        <Outlet />
      </div>
    </Box>
  );
}
